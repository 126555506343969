import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Site } from '../_models/site';
import { User } from '../_models/user';
import { AuthenticationService } from './authentication.service';
import { OrdinalPipe } from '../application-pipes/ordinal.pipe';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  currentUser: User | null;
  currentTenantId = '';
  public sites$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public siteBuildings$: BehaviorSubject<any[]> = new BehaviorSubject(
    undefined
  );
  public buildingAssets$: BehaviorSubject<any[]> = new BehaviorSubject(
    undefined
  );
  public buildingRooms$: BehaviorSubject<any[]> = new BehaviorSubject(
    undefined
  );
  public assetTypes$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public settings$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public tenants$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  // All Sentinels
  public sentinels$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  // All Assets
  public assets$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public gateways$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private ordinalPipe: OrdinalPipe
  ) {
    this.authenticationService.currentAccountValue.subscribe(x => {
      this.currentUser = x;
      this.currentTenantId = x?.tenantId;
    });
  }

  public getDashboard() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/dashboard`
    );
  }

  public getRoles() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/roles`
    );
  }

  public getSiteDetail(siteId) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sites/${siteId}`
    );
  }

  public getDeviceDetails(deviceIds: string[], start, end) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/data/detailed`,
      { deviceIds, start, end }
    );
  }

  public sendFlushCommand(data, assetId: string) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${assetId}/flush`,
      data
    );
  }

  public getFlushesByQuery(query: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/flushes?metadata.${query}`
    );
  }

  public getFlushDetail(flushId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/flushes/${flushId}`
    );
  }

  public getDeviceModels() {
    let obs = this.httpClient.get<any>(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices/models`
    );
    obs.subscribe((res: any) => {
      if (res) this.tenants$.next(res);
    });
    return obs;
  }

  public getAssets() {
    return this.assets$.asObservable();
  }

  public getGateways() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/gateways`
    );
  }

  public createGateway(gateway: any) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/gateways`,
      gateway
    );
  }

  public updateGateway(gateway: any) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/gateways/${gateway._id}`,
      gateway
    );
  }

  public deleteGateway(gateway) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/gateways/${gateway._id}`
    );
  }

  public getGatewayModels() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/gateway-models`
    );
  }

  public getAssetTypes() {
    let obs = this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/types`
    );

    obs.subscribe((res: any) => {
      if (res) this.assetTypes$.next(res);
    });

    return obs;
  }

  addAsset(asset: any) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/provision`,
      asset
    );
  }

  public getAssetDetails(assetId) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${assetId}`
    );
  }

  public getAssetsByBuilding(buildingId) {
    let buildingAssetsObservable = this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets?building=${buildingId}`
    );
    buildingAssetsObservable.subscribe((res: any[]) => {
      if (res) {
        let data = res.map((asset: any) => {
          let location = '';
          if (asset.attributes.location && !asset.attributes.floor) {
            location = asset.attributes.location;
          } else if (asset.attributes.floor || asset.attributes.floor === 0) {
            location =
              this.ordinalPipe.transform(asset.attributes.floor) + ' floor';
          }
          return {
            ...asset,
            name: location ? asset.name + ' • ' + location : asset.name,
          };
        });
        this.buildingAssets$.next(data);
      }
    });
    return buildingAssetsObservable;
  }

  public buildingReport(buildingId, start, end) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${buildingId}/report`,
      { start, end }
    );
  }

  public buildingReportCO2(buildingId, start, end) {
    let obs = this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${buildingId}/report/co2`,
      { start, end }
    );
    obs.subscribe((res: any) => {
      console.log('res:', res);
      this.buildingRooms$.next(res);
    });
    return obs;
  }

  public uploadBuildingAttachment(buildingId, data: FormData) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${buildingId}/upload`,
      data
    );
  }

  public downloadFile(fileId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/files/${fileId}/download`,
      {
        responseType: 'blob',
      }
    );
  }

  public getBuildingAttachment(buildingId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${buildingId}/files`
    );
  }

  public assetReport(assetId, start, end) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${assetId}/report`,
      { start, end }
    );
  }

  public assetReportCO2(assetId, start, end) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${assetId}/co2-report`,
      { start, end }
    );
  }

  public getSentinels() {
    return this.sentinels$.asObservable();
  }

  fetchSentinels() {
    this.httpClient
      .get(
        `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sentinels`
      )
      .subscribe(res => {
        if (res) this.sentinels$.next(res);
      });
  }

  // public createAsset(asset) {
  //   return this.httpClient.post(
  //     `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets`,
  //     asset
  //   );
  // }

  public updateAsset(asset: any) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${asset._id}`,
      asset
    );
  }

  public deleteAsset(asset) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/${asset._id}`
    );
  }

  public deleteAssetAndDevice(asset) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/unprovision`,
      asset
    );
  }

  public getDevices() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices`
    );
  }

  public replaceDevice(device: any) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/replace-device`,
      device
    );
  }

  public getDeviceById(deviceId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices/${deviceId}`
    );
  }

  public createDevice(device) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices`,
      device
    );
  }

  public updateDevice(device) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices/${device._id}`,
      device
    );
  }

  public deleteDevice(device) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/devices/${device._id}`
    );
  }

  public getUsers() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/users`
    );
  }

  public getUserById(userId) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/users/${userId}`
    );
  }

  public createUser(user) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/users/invite`,
      user
    );
  }

  public updateUser(user) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/users/${user._id}`,
      user
    );
  }

  public deleteUser(user) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/users/${user._id}`
    );
  }

  public getRules() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules`
    );
  }

  public getRuleById(ruleId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules/${ruleId}`
    );
  }

  public getRulesByAssetId(assetId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules?asset=${assetId}`
    );
  }

  public createRule(rule) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules`,
      rule
    );
  }

  public updateRule(rule) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules/${rule._id}`,
      rule
    );
  }

  public deleteRule(rule) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules/${rule._id}`
    );
  }

  public getScheduledFlushing() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules`
    );
  }

  public createSchedule(schedule) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules`,
      schedule
    );
  }

  public updateSchedule(schedule) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules/${schedule._id}`,
      schedule
    );
  }

  public getScheduleById(id: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules/${id}`
    );
  }

  public getScheduleByAssetId(assetId: string) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules?asset=${assetId}`
    );
  }

  public deleteSchedule(schedule) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/schedules/${schedule._id}`
    );
  }

  public getInvocationsByRuleId(
    ruleId: string,
    startDate: string,
    endDate: string,
    limit = 10
  ) {
    const params = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('limit', limit);
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/rules/${ruleId}/invocations`,
      { params }
    );
  }

  public updateSettings(settings) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/settings`,
      settings
    );
  }

  public getBuildings() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings`
    );
  }

  public updateBuilding(building: any) {
    return this.httpClient.patch(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${building._id}`,
      building
    );
  }

  public getBuildingsForSite(siteId) {
    let buildingObservable = this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings?site=${siteId}`
    );
    buildingObservable.subscribe((res: any[]) => {
      if (res) this.siteBuildings$.next(res);
    });
    return buildingObservable;
  }

  public getBuilding(buildingId) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/buildings/${buildingId}`
    );
  }

  public getSites() {
    let sitesObservable = this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sites`
    );
    sitesObservable.subscribe((res: any[]) => {
      if (res) this.sites$.next(res);
    });
    return sitesObservable;
  }

  public createSite(site) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sites`,
      site
    );
  }

  public updateSite(siteId, data) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sites/${siteId}/buildings`,
      data
    );
  }

  public deleteSite(site) {
    return this.httpClient.delete(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/sites/${site._id}`
    );
  }

  public getSettings() {
    const sub = this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/settings`
    );
    sub.subscribe(res => {
      this.settings$.next(res);
    });
    return sub;
  }

  public getHealth() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/health`
    );
  }

  /* public getTenants() {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/tenants`
    );
  } */

  public getTenants() {
    let obs = this.httpClient.get<any>(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/tenants`
    );
    obs.subscribe((res: any) => {
      if (res) this.tenants$.next(res);
    });
    return obs;
  }

  public getIssueById(issueId) {
    return this.httpClient.get(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/issues/${issueId}`
    );
  }

  public resolveIssue(issueId) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/issues/${issueId}/resolve`,
      {}
    );
  }

  public addCommentToIssue(issueId: string, comment: string) {
    return this.httpClient.post(
      `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/issues/${issueId}/comment`,
      { comment }
    );
  }
}
