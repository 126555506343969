import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from '../_models/user';

import { AuthenticationService } from '../_services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  exceptions = ['search', 'rooms'];

  exceptionExists(route) {
    console.log(`AuthGuard:exceptionExists Checking if exception exists for route ${route}`);
    if (this.exceptions.indexOf(route) >= -1) {
      console.log('AuthGuard:exceptionExists Exception exists');
      return true;
    } else {
      console.log("AuthGuard:exceptionExists Exception doesn't exist");
      return false;
    }
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('AuthGuard:canActivate: Checking if user can go to route');
    this.modalService.dismissAll();
    let token = route.queryParams['token']    
    if (token) {
      console.log('AuthGuard:canActivate: Token:', token);
      return this.authenticationService.verifyToken(token).pipe(
        catchError(err => {
          console.log('Handling error locally and rethrowing it...', err);
          this.router.navigate(['/auth/login']);
          return of(false);
        })
      );
    } else {
      console.log('AuthGuard:canActivate: No Token');
    }
    const currentUser = this.authenticationService.currentUserValue;
    console.log('AuthGuard:canActivate: currentUser', currentUser);
    if (currentUser) {
      return true;
    }
    console.log('AuthGuard:canActivate: Not logged in');
    this.router.navigate(['/auth/login']);
    return true;
  }
}
