import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public tenantIdSubject: BehaviorSubject<string>;
  public tenantId: Observable<string>;

  public currentAccount$ = new BehaviorSubject<User | null>(null);
  public accounts$ = new BehaviorSubject<Map<string, User>>(new Map());

  httpLoader$ = new BehaviorSubject<boolean>(false);
  token: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const userFromLocalStorage: User = JSON.parse(
      localStorage.getItem('currentUser')
    );

    this.currentUserSubject = new BehaviorSubject<User>(userFromLocalStorage);
    this.currentUser = this.currentUserSubject.asObservable();

    this.tenantIdSubject = new BehaviorSubject<string>(
      localStorage.getItem('tenantId')
    );
    this.tenantId = this.tenantIdSubject.asObservable();

    // Call getAccountsFromLocalStorage and store the returned value in accountsSubject
    this.accounts$.next(this.getAccountsFromLocalStorage());

    // If We have accounts stored in localStorage, we can check if there is anything in sessionStorage
    // Stores a null in currentAccountSubject if sessionStorage doesn't have an account
    if (this.accounts$.value.size > 0) {
      this.currentAccount$.next(this.getCurrentAccountFromSessionStorage());
      //... you call a function to validate the current account's JWT.
      // Perhaps a good idea to do it every time an account switch happens.
    }
  }

  get currentAllAccountsValue() {
    return this.accounts$?.asObservable();
  }

  get currentAccountValue() {
    return this.currentAccount$?.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentAccount$?.value;
    // return this.currentUserSubject.value;
  }

  public get tenantIdValue(): string {
    return this.tenantIdSubject.value;
  }

  getLoading() {
    return this.httpLoader$.asObservable();
  }

  setLoading(value: boolean) {
    this.httpLoader$.next(value);
  }

  verifyToken(token: string): Observable<boolean> {
    const tenantId = this.tenantIdSubject.value;
    console.log(`Verifying token for tenant ${tenantId}`);
    return token
      ? this.http
          .get(
            `${environment.apiPrefix}${tenantId}${environment.apiSuffix}/users/profile`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .pipe(
            tap(res => (localStorage.data = JSON.stringify(res))),
            map(
              (user: any) => {
                console.log('Authenticated User', user);
                user['token'] = token;
                user['tenantId'] = tenantId;
                localStorage.setItem(
                  `account-${user._id}`,
                  JSON.stringify(user)
                );
                sessionStorage.setItem('currentAccount', user._id);
                this.accounts$.value.set(`account-${user._id}`, user);
                this.currentAccount$.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('tenantId', tenantId);
                this.currentUserSubject.next(user);
                this.tenantIdSubject.next(tenantId);
                return true;
              },
              error => false
            )
          )
      : of(false);
  }

  login(email: string, password: string, tenantId: string) {
    return this.http
      .post<any>(
        `${environment.apiPrefix}${tenantId}${environment.apiSuffix}/users/login`,
        { email, password }
      )
      .pipe(
        map((user: any) => {
          user['tenantId'] = tenantId;
          localStorage.setItem(`account-${user._id}`, JSON.stringify(user));
          sessionStorage.setItem('currentAccount', user._id);
          this.accounts$.value.set(`account-${user._id}`, user);
          this.currentAccount$.next(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('tenantId', tenantId);
          this.currentUserSubject.next(user);
          this.tenantIdSubject.next(tenantId);
          return user;
        })
      );
  }

  switchAccount(_id: string) {
    const currentAccount = this.accounts$.value.get(`account-${_id}`) || null;
    this.currentUserSubject.next(currentAccount);
    this.currentAccount$.next(currentAccount);

    sessionStorage.setItem('currentAccount', _id);
    localStorage.setItem('currentUser', JSON.stringify(currentAccount));

    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  private getAccountsFromLocalStorage(): Map<string, User> {
    const accounts: Map<string, User> = new Map();
    const localStorageLength = localStorage.length;

    for (let i = 0; i < localStorageLength; i++) {
      const item = localStorage.key(i);
      if (item?.includes('account-', 0)) {
        accounts.set(item, JSON.parse(localStorage.getItem(item) || '{}'));
      }
    }
    return accounts;
  }

  private getCurrentAccountFromSessionStorage(): User | null {
    const currentAccountUsername = sessionStorage.getItem('currentAccount');
    return (
      this.accounts$.value.get(`account-${currentAccountUsername}`) || null
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tenantId');
    this.currentUserSubject.next(null);
    this.tenantIdSubject.next(null);

    // Remove account from localStorage and sessionStorage
    localStorage.removeItem(
      `account-${this.currentAccount$?.value?._id}` || ''
    );
    sessionStorage.removeItem('currentAccount');

    // Remove account from accounts and nullify currentAccountSubject and currentAccount
    this.accounts$.value.delete(`account-${this.currentAccount$?.value?._id}`);
    this.currentAccount$.next(null);

    // Navigate to login
    this.router.navigate(['/login']);
  }
}
