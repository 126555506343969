export const environment = {
  production: true,
  apiPrefix: 'https://',
  apiSuffix: '.api.semvar.com/api',
  envVar: {
    NAME: 'Semvar MGMT',
    LOGO: 'https://semvar-general.s3.eu-west-1.amazonaws.com/logo-dark.png',
    LOGO_WHITE:
      'https://semvar-general.s3.eu-west-1.amazonaws.com/logo-light.png',
    PRIMARY_COLOR: '#031926',
    FAVICON: 'https://semvar-general.s3.eu-west-1.amazonaws.com/favicon.png',
  },
};
