import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AuthGuard } from '../app/_helpers/auth.guard';
import { environment } from 'src/environments/environment';
const name = environment.envVar.NAME;

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pages/dashboard/dashboard.component').then(
            m => m.DashboardComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Dashboard`,
      },
      {
        path: 'gateways',
        loadComponent: () =>
          import('./pages/gateways/gateways.component').then(
            m => m.GatewaysComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Gateways`,
      },
      {
        path: 'tenants',
        loadComponent: () =>
          import('./pages/tenants/tenants.component').then(
            m => m.TenantsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Tenants`,
      },
      {
        path: 'devices',
        loadComponent: () =>
          import('./pages/devices/devices.component').then(
            m => m.DevicesComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Devices`,
      },
      {
        path: 'devices/:deviceId',
        loadComponent: () =>
          import('./pages/device-detail/device-detail.component').then(
            m => m.DeviceDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Device Details`,
      },
      {
        path: 'rules',
        loadComponent: () =>
          import('./pages/rules/rules.component').then(m => m.RulesComponent),
        canActivate: [AuthGuard],
        title: `${name} - Rules`,
      },
      {
        path: 'rules/:ruleId',
        loadComponent: () =>
          import('./pages/rule-detail/rule-detail.component').then(
            m => m.RuleDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Rule Detail`,
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./pages/users/users.component').then(m => m.UsersComponent),
        canActivate: [AuthGuard],
        title: `${name} - Users`,
      },
      {
        path: 'users/:userId',
        loadComponent: () =>
          import('./pages/user-detail/user-detail.component').then(
            m => m.UserDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - User Details`,
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('./pages/settings/settings.component').then(
            m => m.SettingsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Settings`,
      },
      {
        path: 'not-allowed',
        loadComponent: () =>
          import('./pages/no-permission/no-permission.component').then(
            m => m.PermissionComponent
          ),
        title: `${name} - Not Allowed`,
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/auth-layout/auth-layout.module').then(
            m => m.AuthLayoutModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
