// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-group {
  position: relative;
}
.search-group i {
  top: 50%;
  font-size: 16px;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.search-group input {
  border-radius: 25px;
  padding-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/search-filter/search-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,QAAA;EACA,eAAA;EACA,UAAA;EACA,kBAAA;EACA,2BAAA;AAEJ;AAAE;EACE,mBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".search-group {\n  position: relative;\n  i {\n    top: 50%;\n    font-size: 16px;\n    left: 15px;\n    position: absolute;\n    transform: translateY(-50%);\n  }\n  input {\n    border-radius: 25px;\n    padding-left: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
